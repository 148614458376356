import * as React from "react";
import {ASSETS} from "../common/images";

const Banner = () => {
    return (
 <section className="gb-banner-wrapper">
            <span className="common-header-shape-1" />
            <img src={ASSETS.HOME.CommonHeaderShape2} className="common-header-shape-2" alt="common-header-shape-2" />
            <span className="common-header-shape-3" />
            <img src={ASSETS.HOME.CommonHeaderShape4} className="common-header-shape-4" alt="common-header-shape-4" />
            <span className="common-header-shape-5" />
            <span className="common-header-shape-6" />
            <span className="common-header-shape-7" />
            <span className="common-header-shape-8" />
            <span className="common-header-shape-9" />
            <img src={ASSETS.HOME.CommonHeaderShape6} className="common-header-shape-10" alt="common-header-shape-10" />
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                        <div className="banner-title-wrapper wow pulse" data-wow-duration="0.4s" data-wow-iteration="2" data-wow-delay="1s">
                            <h1 className="title gb-color-black">Truly Flexible<br/><span className="gb-color-red">Digital Marketing</span></h1>
                            <p className="gb-description-med gb-color-black">clean design and advanced digital solutions</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="banner-img text-center">
                            <img src={ASSETS.HOME.HomeBannerIcon} alt="home banner icon" className="wow bounceInUp"  data-wow-iteration="1" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner;
