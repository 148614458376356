import * as React from "react";
import {ASSETS} from "../../common/images";
import RightCommonHeader from "../../common/right-sec-title";
import ChooseUsListing from "./why-choose-us-listing";

const WhyChooseUs = () => {
    return (
        <section className="why-choose-us-wrapper">
            <div className="container">
                <div className="row flex-column-reverse flex-md-row">
                    <div className="col-md-6">
                        <div className="why-choose-us-col-left pe-lg-5 pe-md-0">
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12 col-6 mb-md-0 mb-3">
                                            <div className="common-counts-box-wrapper gb-mt-50">
                                                <div className="common-count-number-wrapper">
                                                    <p className="total-number-counts"  data-count="15">0</p>
                                                    <img src={ASSETS.HOME.PlusIconRed} alt="plus icon" className="plus-icon" />
                                                </div>
                                                <p className="count-title">Awards Winning</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-6 mb-md-0 mb-3">
                                            <div className="common-counts-box-wrapper gb-mt-25">
                                                <div className="common-count-number-wrapper">
                                                    <p className="total-number-counts" data-count="354">0</p>
                                                    <img src={ASSETS.HOME.PlusIconRed} alt="plus icon" className="plus-icon" />
                                                </div>
                                                <p className="count-title">Successful Projects</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12 col-6 mb-md-0 mb-3">
                                            <div className="common-counts-box-wrapper">
                                                <div className="common-count-number-wrapper">
                                                    <p className="total-number-counts" data-count="235">0</p>
                                                    <img src={ASSETS.HOME.PlusIconRed} alt="plus icon" className="plus-icon" />
                                                </div>
                                                <p className="count-title">Satisfied Clients</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-6 mb-md-0 mb-3">
                                            <div className="common-counts-box-wrapper gb-mt-25">
                                                <div className="common-count-number-wrapper">
                                                    <p className="total-number-counts" data-count="20">0</p>
                                                    <img src={ASSETS.HOME.PlusIconRed} alt="plus icon" className="plus-icon" />
                                                </div>
                                                <p className="count-title">Team Members</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">


                        <div className="row">
                            <div className="col-md-12">
                                <RightCommonHeader className={'why-choose-us-col-right'} title={'a few words'} subTitle={'Why Choose Us'} description={'We’ve got a lot of awards for our products and services that became popular in the world.'} />
                            </div>
                        </div>
                        <div className="px-lg-5 px-3 mt-5 mt-3 mt-lg-3 mt-xl-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="why-choose-us-common-listing-wrapper-main">
                                    <ChooseUsListing title={'Modern Technologies'} description={'Discussion is an important thing in the development process. Thus ne ideas and ingenious solutions are born'} />
                                        <span className="choose-us-circle" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <ChooseUsListing title={'Huge Digital Experience'} description={'Our company has been developing digital products for 15 years. We’ve got a lot of awards for our work.'} />
                                </div>
                                <div className="col-md-12">
                                    <ChooseUsListing title={'Modern Technologies'} description={'Discussion is an important thing in the development process. Thus ne ideas and ingenious solutions are born'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span className="why-choose-us-dots-bg" />
        </section>
    )
}
export default WhyChooseUs;
