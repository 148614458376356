import * as React from "react";
import {ASSETS} from "../../common/images";

const PortfolioListing = () => {

    const portfolioListing = [
        {
            portfolioImg: ASSETS.HOME.Portfolio1
        },
        {
            portfolioImg: ASSETS.HOME.Portfolio2
        },
        {
            portfolioImg: ASSETS.HOME.Portfolio3
        },
        {
            portfolioImg: ASSETS.HOME.Portfolio4
        },
        {
            portfolioImg: ASSETS.HOME.Portfolio5
        },
        {
            portfolioImg: ASSETS.HOME.Portfolio6
        },
        {
            portfolioImg: ASSETS.HOME.Portfolio7
        },
        {
            portfolioImg: ASSETS.HOME.Portfolio8
        },

    ]

    return (
        <div className="row">

            {portfolioListing.map( (row, index) => (
                <div className="col-md-3 col-6 p-0" key={'portfolio-'+index}>
                    <div className="gb-common-portfolio">
                        <img src={row.portfolioImg} alt="" />
                    </div>
                </div>
            ) )}
        </div>
    )
}
export default PortfolioListing;