import * as React from "react";
import {ASSETS} from "../../common/images";
import LeftCommonHeader from "../../common/left-sec-title";
import PortfolioListing from "./portfolio-listing";

const Portfolio = () => {
    return (
        <section className="portfolio-sec-wrapper pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <LeftCommonHeader className={'portfolio-header-wrapper'} title={'portfolio'} subTitle={'Our Latest Cases'} description={'You may be interested in what we can offer you. More services you can find below. We do everything at a high level'} />
                    </div>
                </div>
               <PortfolioListing />
            </div>
        </section>
    )
}
export default Portfolio;
