import * as React from "react";
import {ASSETS} from "../../common/images";
import LeftCommonHeader from "../../common/left-sec-title";
import ServicesList from "./services-list";

const OurServices = () => {

    const servicesList = [
        {
         title1: 'Web',
         title2: 'Development',
         icon: ASSETS.HOME.ServiceIcon1
        },
        {
         title1: 'App',
         title2: 'Development',
         icon: ASSETS.HOME.ServiceIcon2
        },
        {
         title1: 'Business',
         title2: 'Development',
         icon: ASSETS.HOME.ServiceIcon3
        },
        {
         title1: 'Graphic',
         title2: 'Design',
         icon: ASSETS.HOME.ServiceIcon4
        }
    ]

    return (
        <section className="our-services-sec-wrapper pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <LeftCommonHeader title={'our services'} subTitle={'We Do Everything'} description={'You may be interested in what we can offer you. More services you can find below. We do everything at a high level'} />
                    </div>
                </div>
                <div className="row">
                    {servicesList.map( (row, index) => (
                        <div className="col-md-3 col-6" key={'service-'+index}>
                            <ServicesList title1={row.title1} title2={row.title2} icon={row.icon} />
                        </div>
                    ) )}
                </div>
            </div>
        </section>
    )
}
export default OurServices;
