import * as React from "react";
import {ASSETS} from "../../common/images";

const ChooseUsListing = (props) => {
  const {title, description} = props;
    return (
        <div className="why-choose-us-common-listing">
            <div className="why-choose-us-left-icon">
                <img src={ASSETS.HOME.CircleTick} className="wow flip"  data-wow-iteration="1" alt="tick circle" />
            </div>
            <div className="why-choose-us-right-text">
                <p className="title">{title}</p>
                <p className="description">{description}</p>
            </div>
        </div>
    )
}
export default ChooseUsListing;
