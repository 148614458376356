import * as React from "react";
import Layout from "../components/layout";
import Banner from "../components/home/banner";
import WhatWeDo from "../components/home/what-we-do";
import Portfolio from "../components/home/portfolio/index";
import Testimonials from "../components/home/testimonials";
import ContactUs from "../components/home/contact-us";
import WhyChooseUs from "../components/home/why-choose-us";
import OurServices from "../components/home/our-services";

const IndexPage = () => {
  return (
      <Layout>
          <Banner />
          <WhatWeDo />
          <OurServices />
          <WhyChooseUs />
          <Portfolio />
          <Testimonials />
          <ContactUs />
      </Layout>
  )
}
export default IndexPage;
