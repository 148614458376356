import * as React from "react";
import {ASSETS} from "../common/images";

const WhatWeDo = () => {
    return (
        <section className="what-we-do-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="what-we-do-col-left pe-md-5 pe-0 wow slideInLeft" data-wow-duration="1.5s" data-wow-delay="1.2s">
                            <img src={ASSETS.HOME.OurExpert} className="expert-img" alt="our expert image" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="what-we-do-col-right px-lg-5 px-0">
                            <h4 className="common-sec-sub-title text-uppercase mb-2 mb-md-3 mb-lg-3 mb-xl-5 gb-color-red sub-title-common-right-divider">what we do</h4>
                            <h2 className="common-sec-title gb-color-black mb-2 mb-md-3 mb-lg-3 mb-xl-5">We are Experts in Our Field</h2>
                            <p className="gb-description-small gb-color-black mb-2 mb-md-3">Our digital company of professionals has been developing products for 5 years.</p>
                            <p className="gb-description-small gb-color-black mb-2 mb-md-3">We’ve got a lot of awards for our work and develop applications that became popular in the world. Every morning in our company begins with hot coffee and a stand-up. Discussion is an important thing in the development process. Thus new ideas and ingenious solutions are born. Then we get to work with renewed vigor and inspiration. We are never bored.</p>
                            <button className="text-capitalize gb-btn-md-red btn-know-more">know more<img src={ASSETS.COMMON.ArrowRight} className="arrow-icon" alt="right arrow icon" /></button>
                            <span className="white-circle" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default WhatWeDo;
