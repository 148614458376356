import * as React from "react";
import {ASSETS} from "../common/images";
import Layout from "../layout";
import RightCommonHeader from "../common/right-sec-title";

const Testimonials = () => {
    return (
        <div>
            <section className="mt-5 testimonial-header-right-wrapper">
                <div className="container">
                    <span className="testimonial-icon-1" />
                    <span className="testimonial-icon-2" />
                    <span className="testimonial-icon-3" />
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <RightCommonHeader className={'testimonial-header-right'} title={'client says'} subTitle={'What Our Client Says?'} description={'We’ve got a lot of awards for our products and services that became popular in the world.'} />
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </section>

            <section className="testimonial-sec-wrapper">
                <span className="gb-testimonial-icon-1" />
                <span className="gb-testimonial-icon-2" />
                <span className="gb-testimonial-icon-3" />
                <img src={ASSETS.HOME.DotsBox1} alt="" className="gb-testimonial-icon-4" />
                <img src={ASSETS.HOME.CommonHeaderShape2} alt="" className="gb-testimonial-icon-5" />
                <span className="gb-testimonial-icon-6" />
                <img src={ASSETS.HOME.DotsBox1} alt="" className="gb-testimonial-icon-7" />
                <img src={ASSETS.HOME.CommonHeaderShape5} alt="" className="gb-testimonial-icon-8" />
                <span className="gb-testimonial-icon-9" />
                <span className="gb-testimonial-icon-10" />
                <span className="gb-testimonial-icon-11" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-10">
                            <div className="testimonial-slider-wrapper">

                                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img src={ASSETS.HOME.Testimonial1} alt="" className="testimonial-common-img" />
                                            <div className="common-testimonial-description">
                                                <p className="testimonial-desc"><img src={ASSETS.HOME.QuoteLeft} alt="" className="testimonial-common-quote testimonial-quote-left" /> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore dolore magna <img src={ASSETS.HOME.QuoteRight} alt="" className="testimonial-common-quote testimonial-quote-right" /></p>
                                                <p className="testimonial-user-name">Rubiya Mond De-Patrica</p>
                                                <p className="testimonial-user-post">CEO, Zexbay-Fine</p>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img src={ASSETS.HOME.Testimonial2} alt="" className="testimonial-common-img" />
                                            <div className="common-testimonial-description">
                                                <p className="testimonial-desc"><img src={ASSETS.HOME.QuoteLeft} alt="" className="testimonial-common-quote testimonial-quote-left" /> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore dolore magna <img src={ASSETS.HOME.QuoteRight} alt="" className="testimonial-common-quote testimonial-quote-right" /></p>
                                                <p className="testimonial-user-name">Rubiya Mond De-Patrica</p>
                                                <p className="testimonial-user-post">CEO, Zexbay-Fine</p>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img src={ASSETS.HOME.OurExpert} alt="" className="testimonial-common-img" />
                                            <div className="common-testimonial-description">
                                                <p className="testimonial-desc"><img src={ASSETS.HOME.QuoteLeft} alt="" className="testimonial-common-quote testimonial-quote-left" /> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore dolore magna <img src={ASSETS.HOME.QuoteRight} alt="" className="testimonial-common-quote testimonial-quote-right" /></p>
                                                <p className="testimonial-user-name">Rubiya Mond De-Patrica</p>
                                                <p className="testimonial-user-post">CEO, Zexbay-Fine</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-control-btns-wrapper">
                                        <button className="carousel-control-prev" type="button"
                                                data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span className="" aria-hidden="true"><img src={ASSETS.COMMON.ArrowLeftBlack} alt="" /></span>
                                        </button>
                                        <button className="carousel-control-next" type="button"
                                                data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <span className="" aria-hidden="true"><img src={ASSETS.COMMON.ArrowRightBlack} alt="" /></span>
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-1">
                        </div>
                    </div>


                </div>
            </section>
        </div>
    )
}
export default Testimonials;
